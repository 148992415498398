import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        nxTeams: [],
        teams: [],
        categories: []
    },

    getters: {
        nxTeams: state => state.nxTeams,
        teams: state => state.teams,
        categories: state => state.categories
    },

    mutations: {
        add(state, { team }) {
            state.teams.splice(0, 0, team)
        },

        update(state, { id, team }) {
            const index = state.teams.findIndex(t => t.id === id)
            if (index !== -1) {
                state.teams.splice(index, 1, {
                    ...state.teams[index],
                    ...team
                })
            } else {
                state.teams.push(team)
            }
        },

        remove(state, { id }) {
            const index = state.teams.findIndex(t => t.id === id)
            if (index !== -1) {
                const team = state.teams[index]
                // on enlève seulement si pas dans Nx
                const nx = state.nxTeams.find(t => t.id === team.nx_id)
                if (!nx) {
                    return
                }
                state.teams.splice(index, 1)
            }
        }
    },

    actions: {
        async teams({ state }) {
            const res = await Promise.all([
                Vue.prototype.$api.get('/nx/teams'),
                !state.teams.length && Vue.prototype.$api.get('/teams')
            ])
            state.nxTeams = res[0].data
            if (res[1]) {
                state.teams = res[1].data
            }
            return [res[0].data, res[1].data]
        },

        async import({ commit }, { id, nxId }) {
            const resNx = await Vue.prototype.$api.get(`/nx/teams/${nxId}`)
            const nxTeam = resNx.data

            const params = {
                ...nxTeam,
                nx_team_id: nxTeam.id
            }
            const res = await Vue.prototype.$api.post(`/nx/teams/${nxId}`, params)

            commit('update', { id: res.data.id, team: res.data })

            return res.data
        },

        async get(context, { id }) {
            let res, imported
            if (String(id).match(/^[0-9]+$/)) {
                res = await Vue.prototype.$api.get(`/nx/teams/${id}`)
                imported = false
            } else {
                res = await Vue.prototype.$api.get(`/teams/${id}`, {
                    params: {
                        include_categories: true
                    }
                })
                imported = true
            }
            return {
                biography_i18n: {},
                ...res.data,
                imported
            }
        },

        async list(context, { ids = undefined, search } = {}) {
            const res = await Vue.prototype.$api.get('/teams', {
                params: {
                    team_ids: ids,
                    search
                }
            })
            return res.data
        },

        async update({ commit }, { id, team }) {
            const res = await Vue.prototype.$api.put(`/teams/${id}`, team)
            commit('update', { id: id, team: res.data })
            return res.data
        },

        async remove({ commit }, { id }) {
            await Vue.prototype.$api.delete(`/teams/${id}`)
            commit('remove', { id: id })
        },

        async removeByIndexes({ state }, { indexes }) {
            const ids = state.teams.filter((_, i) => indexes.indexOf(i) !== -1).map(item => item.id)
            const res = await Promise.all(ids.map(id =>
                Vue.prototype.$api.delete(`/teams/${id}`)
            ))

            state.teams = state.teams.filter((_, i) => !indexes.includes(i))

            return res
        },

        async categories({ state }) {
            if (state.categories.length) {
                return state.categories
            }
            const res = await Vue.prototype.$api.get('/teams-categories')
            state.categories = res.data
            return res.data
        },

        async getCategory(context, { id }) {
            const res = await Vue.prototype.$api.get(`/teams-categories/${id}`, {
                meta: { lang: '*' },
                params: {
                    include_teams: true
                }
            })
            return res.data
        },

        async addCategory({ state }, { category }) {
            const res = await Vue.prototype.$api.post('/teams-categories', { category })
            state.categories.push(res.data)
            return res.data
        },

        async updateCategory({ state }, { id, category }) {
            await Vue.prototype.$api.put(`/teams-categories/${id}`, { category })
            const index = state.categories.findIndex(c => c.id === id)
            if (index !== -1) {
                category.name = category.name_i18n.fr
                state.categories.splice(index, 1, { ...category })
            } else {
                state.categories.push(category)
            }
        },

        async removeCategory({ state }, { id }) {
            await Vue.prototype.$api.delete(`/teams-categories/${id}`)
            const index = state.categories.findIndex(c => c.id === id)
            if (index !== -1) {
                state.categories.splice(index, 1)
            }
        }
    }
}
