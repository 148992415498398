import Vue from 'vue'
import store from '../../store'
import Axios from 'axios'
import AxiosFinally from 'promise.prototype.finally'
import AxiosRetry from 'axios-retry'
import lodash from 'lodash'

const lnInterceptor = function(req) {
    const dln = store.getters['ln/defaultLn']
    if (dln) {
        const navln = navigator.userLanguage || navigator.language
        req.headers['Accept-Language'] = `${req.meta?.lang === '*' ? '*,' : ''}${store.getters['ln/current']},${navln},${dln}`
    }
    return req
}

const errorInterceptor = function(err) {
    if (!err.response) {
        return Promise.reject(err)
    }
    const isLogin = lodash.get(err, 'response.data.error.code') === 'invalidCredentials'
    if (err.response.status === 401 && !isLogin) {
        // on issue un nouveau token. On se base sur le axios $http, qui n'a
        // pas d'errorInterceptor. Parce que sinon, c'est la boucle infinie
        return store.dispatch('auth/refreshToken', { useHttp: true })
            .catch(errRefresh => {
                store.commit('auth/logout')
                // le refresh n'a pas marché, on renvoie au login
                if (errRefresh.response && errRefresh.response.status === 401) {
                    store.commit('auth/accessError')
                }
                return Promise.reject(err)
            })
            .then(() => {
                // redo l'action qui a planté, avec le axios $http qui n'a pas
                // d'errorInterceptor. Parce que sinon, c'est la boucle infinie
                return Vue.prototype.$http.request(err.config)
            })
    }
    if (isLogin) {
        store.commit('auth/logout')
    }
    return Promise.reject(err)
}
// pour gérer le .finally
AxiosFinally.shim()

Vue.prototype.$http = Axios.create({})
Vue.prototype.$http.interceptors.request.use(lnInterceptor)
Vue.prototype.$http.interceptors.response.use(res => {
    store.commit('load/retry', false)
    return res
})

Vue.prototype.$api = Axios.create({
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
    baseURL: '' // sera configuré lors du boot
})
Vue.prototype.$api.interceptors.request.use(lnInterceptor)
Vue.prototype.$api.interceptors.response.use(
    res => {
        store.commit('load/retry', false)
        return res
    },
    errorInterceptor
)

AxiosRetry(Vue.prototype.$api, {
    retryCondition: err => AxiosRetry.isNetworkOrIdempotentRequestError(err) || lodash.get(err, 'response.data.retryable'),
    retryDelay: retryCount => {
        store.commit('load/retry', retryCount > 1 && retryCount < 5)
        return AxiosRetry.exponentialDelay(retryCount) + (5 * 1000)
    },
    retries: 5
})

export default Axios
