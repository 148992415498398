<template>
    <v-dialog v-model="show" max-width="500" :persistent="loading">
        <template #activator="{ on, attrs }">
            <v-btn v-show="visible" color="primary" fab absolute left small v-on="on" v-bind="attrs">
                <v-icon small>$vuetify.icons.baseInfo</v-icon>
            </v-btn>
        </template>
        <v-card v-if="vacation">
            <v-form v-model="valid" @submit.stop.prevent="submit">
                <vlab-base-card-title :title="title" :dismissible="!loading" @close="show = false" single-line />
                <v-card-text v-if="vacation">
                    <hdv-base-image
                        ref="uploadBanner"
                        class="mb-0"
                        :label="$t('salecatalog.uploadBanner')"
                        :auto-start="!!id"
                        :preview.sync="vacation.banner_url_preview"
                        :src.sync="vacation.banner_url"
                        :url="`/sales/${saleId}/vacations/${id}/banner`"
                        @loaded="v => loading = v"
                        @remove="removeImage('banner')" />

                    <hdv-base-image
                        ref="uploadCover"
                        class="mb-0"
                        :label="$t('salecatalog.uploadcover')"
                        :auto-start="!!id"
                        :preview.sync="vacation.cover_url_preview"
                        :src.sync="vacation.cover_url"
                        :url="`/sales/${saleId}/vacations/${id}/cover`"
                        @loaded="v => loading = v"
                        @remove="removeImage('cover')" />

                    <hdv-sale-vacation-upload
                        :sale-id="saleId"
                        :vacation-id="id"
                        v-model="vacation.catalog_url"
                        pdf
                        inline
                        type="catalog"
                        @loaded="v => loading = v"
                        class="mb-3">
                        <template #append>
                            <vlab-base-message-btn
                                v-if="vacation.catalog_url"
                                :message="$t('salespecial.removeCatalog')"
                                :message-title="$t('salespecial.removeCatalogTitle')"
                                :message-btn="$t('base.remove')"
                                :loading="loading"
                                @submit="removeDoc('catalog')"
                                color="red"
                                icon />
                            <v-btn color="red" icon target="_blank" :href="vacation.catalog_url" :disabled="!vacation.catalog_url">
                                <v-icon>$vuetify.base.saleCatalogPdf</v-icon>
                            </v-btn>
                        </template>
                    </hdv-sale-vacation-upload>

                    <hdv-sale-vacation-upload
                        :sale-id="saleId"
                        :vacation-id="id"
                        v-model="vacation.results_url"
                        pdf
                        inline
                        type="results"
                        @loaded="v => loading = v">
                        <template #append>
                            <vlab-base-message-btn
                                v-if="vacation.results_url"
                                :message="$t('salespecial.removeResults')"
                                :message-title="$t('salespecial.removeResultsTitle')"
                                :message-btn="$t('base.remove')"
                                :loading="loading"
                                @submit="removeDoc('results')"
                                color="red"
                                icon />
                            <v-btn color="red" icon target="_blank" :href="vacation.results_url" :disabled="!vacation.results_url">
                                <v-icon>$vuetify.base.saleCatalogPdf</v-icon>
                            </v-btn>
                        </template>
                    </hdv-sale-vacation-upload>

                    <vlab-base-switch v-model="vacation.is_house" :label="$t('sale.ishouse')" />

                    <hdv-sale-special-issuu class="mt-4" v-model="vacation.issuu_url" />

                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn type="submit" color="primary" block rounded>{{ $t('base.save') }}</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import VlabBaseMessageBtn from '@/shared/components/base/VlabBaseMessageBtn'
import VlabBaseSwitch from '@/shared/components/base/VlabBaseSwitch'
import HdvSaleVacationUpload from '@/components/sale-vacation/HdvSaleVacationUpload'
import HdvSaleSpecialIssuu from '@/components/sale-special/HdvSaleSpecialIssuu'
import HdvBaseImage from '@/components/base/HdvBaseImage'

export default {
    name: 'hdv-sale-vacation-popup',

    components: { VlabBaseCardTitle, VlabBaseMessageBtn, VlabBaseSwitch, HdvSaleVacationUpload, HdvBaseImage, HdvSaleSpecialIssuu },

    props: {
        visible: { type: Boolean },
        saleId: { type: String },
        id: { type: String },
        title: { type: String }
    },

    data: () => ({
        show: false,
        loading: false,
        valid: false,
        vacation: null
    }),

    watch: {
        show(v) {
            if (v) {
                return this.load()
            }
        }
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('sale/getVacation', { saleId: this.saleId, vacationId: this.id })
                .then(vacation => (this.vacation = vacation))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeDoc(type) {
            this.loading = true
            return this.$store.dispatch('sale/removeVacationDoc', { saleId: this.saleId, vacationId: this.id, type })
                .then(res => {
                    this.vacation[`${type}_url`] = null
                    const message = this.$i18n.t(type === 'catalog' ? 'salespecial.removeCatalogDone' : 'salespecial.removeResultsDone')
                    this.$success(res, { message })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeImage(type) {
            this.loading = true
            return this.$store.dispatch('sale/removeVacationImage', { saleId: this.saleId, vacationId: this.id, type })
                .then(res => {
                    this.vacation[`${type}_url`] = null
                    this.vacation[`${type}_url_preview`] = null
                    this.$success(res, { message: this.$i18n.t('base.imageRemoved', { type }) })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            const vacation = {
                issuu_url: this.vacation.issuu_url,
                is_house: this.vacation.is_house
            }
            return this.$store.dispatch('sale/saveVacation', { saleId: this.saleId, vacationId: this.id, vacation })
                .then(res => this.$success(res, { message: 'base.saved' }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
