import Vue from 'vue'

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {
        async listMissingLots(context, { saleId, vacationId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/am/vacations/${vacationId}/missing-lots`)
            return res.data
        }
    }
}
