import Vue from 'vue'
import moment from 'moment'
import lodash from 'lodash'
import store from './store'

export default {

    async mount() {
        const env = () => {
            const host = `//${window.location.host}`
            const hmap = [
                { s: '//127.0.0.1', env: process.env.VUE_APP_SHOW_ENV_MARKER === 'true' && 'local' },
                { s: '//test', env: 'test' },
                { s: '//staging', env: 'demo' },
                { s: '//', env: '' }
            ]
            const key = hmap.find(h => host.indexOf(h.s) !== -1)
            return key && !!key.env
        }
        const config = {
            api: process.env.VUE_APP_API,
            local: process.env.VUE_APP_LOCAL,
            version: process.env.VUE_APP_VERSION,
            sparkpostAdminTplUrl: process.env.VUE_APP_SPARKPOST_ADMIN_TPL_URL,
            salePreviewUrl: process.env.VUE_APP_WEBSITE_SALE_PREVIEW_URL,
            envMarker: env(),
            i18nNamespace: 'website-admin'
        }
        const bools = ['local', 'envMarker']
        bools.forEach(b => {
            if (config[b] === 'false') {
                config[b] = false
            } else if (config[b] === 'true') {
                config[b] = true
            }
        })

        Vue.prototype.$api.defaults.baseURL = config.api
        const res = await Vue.prototype.$api.get('/config')
        Vue.prototype.$config = lodash.merge(config, ((res && res.data) || {}))

        store.commit('pub/setUrl', { url: Vue.prototype.$config.links.website })

        moment.locale(Vue.prototype.$config.i18n.default)

        // user à priori connecté, on check
        return store.dispatch('auth/loadUser', { check_role: 'admin' })
            // la token n'est plus valable, on déconnecte la personne
            .catch(() => store.commit('auth/logout'))
            .then(() => Vue.prototype.$config)
    }
}
