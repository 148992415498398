<template>
    <hdv-base-actionable-list-item
        :value="highlight.id"
        :enabled="action"
        :ripple="!sorting"
        :class="{ sorting: !sorting }"
        class="draggable-item">
        <v-avatar
            rounded
            size="52"
            color="grey lighten-3">
            <v-img v-if="item.images.length" :src="item.images[0].path_preview | imagecrop({ w: 52, h: 52, f: 'cover' })" alt="image" />
        </v-avatar>
        <v-list-item-content class="pl-4">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('salehighlight.estimates', { down: item.estimate_down, up: item.estimate_up }) }}</v-list-item-subtitle>
            <v-list-item-subtitle><span v-if="sale">{{ sale.title }}, </span>{{ (lot && lot.lot_nb) || item.stock_no}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar v-if="!hideStar" class="unactionable">
            <template v-if="!sorting">
                <hdv-sale-highlight-item-import v-if="!lot" @click:import="clickImport">
                    <template #activator="{ on, props }">
                        <v-btn v-on="on" v-bind="props" color="primary" icon>
                            <v-icon>$vuetify.icons.highlightItemLotImport</v-icon>
                        </v-btn>
                    </template>
                </hdv-sale-highlight-item-import>
            </template>
            <v-icon color="primary" v-else>$vuetify.icons.baseSort</v-icon>
        </v-list-item-avatar>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'
import HdvSaleHighlightItemImport from '@/components/sale-highlight/HdvSaleHighlightItemImport'

export default {
    name: 'hdv-sale-highlight-list-item',
    components: { HdvBaseActionableListItem, HdvSaleHighlightItemImport },
    props: {
        highlight: { type: Object },
        item: { type: Object },
        lot: { type: Object },
        sale: { type: Object },
        action: { type: Boolean },
        sorting: { type: Boolean },
        hideStar: { type: Boolean }
    },
    methods: {
        clickImport({ images }) {
            this.$emit('click:import', {
                images,
                highlightId: this.highlight.id
            })
        }
    }
}
</script>

<style lang="sass">
.sorting
    cursor: default !important
</style>
