<template>
    <hdv-base-actionable-list-item
        :value="special.id"
        :enabled="action"
        :ripple="!sorting"
        :class="{ sorting: !sorting }"
        class="draggable-item"
        :to="sorting ? undefined : { name: 'salespecialform', params: { specialId: special.id } }">
        <v-list-item-avatar rounded>
            <v-img v-if="special.image_url_preview" :src="special.image_url_preview | imagecrop({ w: 80, f: 'cover' })" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ special.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="unactionable">
            <v-btn icon>
                <v-icon v-if="!sorting" color="primary">$vuetify.icons.baseRight</v-icon>
                <v-icon v-else color="primary">$vuetify.icons.baseSort</v-icon>
            </v-btn>
        </v-list-item-action>
    </hdv-base-actionable-list-item>
</template>

<script>
import HdvBaseActionableListItem from '@/components/base/HdvBaseActionableListItem'

export default {
    name: 'acs-sale-special-list-item',
    components: { HdvBaseActionableListItem },
    props: {
        special: { type: Object },
        action: { type: Boolean },
        sorting: { type: Boolean }
    }
}
</script>

<style lang="sass">
.sorting
    cursor: default !important
</style>
