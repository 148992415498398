<template>
    <v-text-field
        v-model="search"
        :label="$t('salelotlist.search')"
        :disabled="disabled"
        append-icon="$vuetify.icons.baseSearch"
        @click:append="$emit('search', search)"
        @keydown="test"
        @keyup.enter="$emit('search', search)"
        outlined />
</template>

<script>
const reg = /[\d,:]/g

export default {
    name: 'hdv-sale-lot-list-search',
    props: {
        value: { type: String },
        disabled: { type: Boolean }
    },
    computed: {
        search: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        test(ev) {
            if (ev.ctrlKey && ['a'].includes(ev.key)) {
                return
            }
            if (['Tab', 'Backspace', 'Delete', 'Home', 'End', 'ArrowLeft', 'ArrowRight'].includes(ev.key)) {
                return
            }
            if ((ev.key || '').match(reg)) {
                return
            }
            ev.stopPropagation()
            ev.preventDefault()
        }
    }
}
</script>
