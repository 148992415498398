<template>
    <v-card flat :loading="loading">
        <v-card-text>
            <div v-marked="$t('departmenthighlight.text', { tags })"></div>

            <hdv-department-highlight-search
                v-model="highlight"
                :department-id="departmentId"
                @input="add" />

        </v-card-text>
        <v-list three-line class="pt-0" :disabled="loading">
            <hdv-base-actionable-list
                v-model="action"
                :total="highlights.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('departmenthighlight.removeText', { count: selected.length, name: selected.length && selected[0].title }) }}</v-card-text>
                </template>
            </hdv-base-actionable-list>

            <v-list-item-group v-model="selected" :multiple="action">
                <draggable
                    :list="highlights"
                    :disabled="loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <div v-for="highlight in highlights" :key="highlight.id">
                        <v-divider />
                        <hdv-sale-highlight-list-item
                            :action="action"
                            :highlight="highlight"
                            :sale="highlight.sale"
                            :lot="highlight"
                            :item="highlight.item"
                            hide-star />
                    </div>
                </draggable>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>

<script>
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import HdvSaleHighlightListItem from '@/components/sale-highlight/HdvSaleHighlightListItem'
import HdvDepartmentHighlightSearch from '@/components/department-highlight/HdvDepartmentHighlightSearch'
import draggable from 'vuedraggable'

export default {
    name: 'hdv-department-highlight',
    components: {
        HdvBaseActionableList,
        HdvSaleHighlightListItem,
        HdvDepartmentHighlightSearch,
        draggable
    },
    props: {
        lots: { type: Array },
        tags: { type: Array },
        departmentId: { type: String }
    },
    data: () => ({
        highlights: [],
        loading: false,
        show: false,
        editItem: null,
        highlight: null,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    watch: {
        departmentId: 'load'
    },
    mounted() {
        return this.load()
    },
    methods: {
        load() {
            this.highlights = this.lots.slice()
        },

        add(lot) {
            if (!lot) {
                return
            }
            this.loading = true
            return this.$store.dispatch('dp/addHighlight', { lotId: lot.id, departmentId: this.departmentId })
                .then(() => this.highlights.push(lot))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('dp/removeHighlightByIds', { departmentId: this.departmentId, lots: this.highlights, ids: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'departmenthighlight.removed', count: this.selected.length })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
