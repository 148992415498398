import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        specials: [],
        count: 0
    },

    getters: {
        specials: state => state.specials,
        count: state => state.count
    },

    mutations: {
        reset(state) {
            state.specials = []
        }
    },

    actions: {

        async list({ state }, { sale_id, offset, limit }) {
            const [res, resCount] = await Promise.all([
                Vue.prototype.$api.get(`/sales/${sale_id}/specials`, {
                    params: {
                        mapping: 'list',
                        include_disabled: true,
                        offset,
                        limit
                    }
                }),
                offset ? null : Vue.prototype.$api.head(`/sales/${sale_id}/specials`)
            ])
            state.specials = res.data

            if (resCount) {
                state.count = Number(resCount.headers['x-hdv-list-count'] || 0)
            }

            return res.data
        },

        async get(context, data) {
            const res = await Vue.prototype.$api.get(`/sales/${data.sale_id}/specials/${data.id}`, {
                meta: { lang: '*' },
                params: {
                    include_highlight: !!data.include_highlight
                }
            })
            return res.data
        },

        async add({ state }, data) {
            const res = await Vue.prototype.$api.post(`/sales/${data.sale_id}/specials`, data.special)
            // pour le titre dans la liste
            state.specials.push(res.data)
            return res.data
        },

        async update({ state }, data) {
            await Vue.prototype.$api.put(`/sales/${data.sale_id}/specials/${data.special.id}`, data.special)
            const index = state.specials.findIndex(s => s.id === data.special.id)
            if (index !== -1) {
                state.specials.splice(index, 1, data.special)
            }
        },

        async remove({ state }, data) {
            await Vue.prototype.$api.delete(`/sales/${data.sale_id}/specials/${data.id}`)
            const index = state.specials.findIndex(s => s.id === data.id)
            if (index !== -1) {
                state.specials.splice(index, 1)
            }
        },

        async removeByIds({ state }, { sale_id, ids }) {
            const res = await Promise.all(ids.map(id =>
                Vue.prototype.$api.delete(`/sales/${sale_id}/specials/${id}`)
            ))

            state.specials = state.specials.filter(item => !ids.includes(item.id))

            return res
        },

        sorts(context, data) {
            return Vue.prototype.$api.post(`/sales/${data.sale_id}/specials-sort`, { special_ids: data.special_ids })
        },

        removeDoc(context, data) {
            return Vue.prototype.$api.delete(`/sales/${data.sale_id}/specials/${data.special_id}/${data.type}`)
        },

        removeImage(context, data) {
            return Vue.prototype.$api.delete(`/sales/${data.sale_id}/specials/${data.special_id}/${data.type}`)
        },

        async lots(context, { filter, saleId, specialId, limit }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/specials/${specialId}/lots`, {
                params: {
                    terms: filter,
                    limit
                }
            })
            return res.data
        },

        async addHighlight(context, { saleId, specialId, lotId }) {
            const res = await Vue.prototype.$api.post(`/sales/${saleId}/specials/${specialId}/highlights`, {
                lot_id: lotId
            })
            return res.data
        },

        async removeHighlight(context, { saleId, specialId, lotId, lots }) {
            await Vue.prototype.$api.delete(`/sales/${saleId}/specials/${specialId}/highlights?lot_id=${lotId}`)

            const index = lots.findIndex(c => c.lot_id === lotId)
            if (index !== -1) {
                lots.splice(index, 1)
            }
        },

        async removeHighlightByIndexes({ dispatch }, { saleId, specialId, indexes, lots }) {
            const ids = lots.filter((_, i) => indexes.indexOf(i) !== -1).map(item => item.lot_id)
            const res = await Promise.all(ids.map(lotId =>
                Vue.prototype.$api.delete(`/sales/${saleId}/specials/${specialId}/highlights?lot_id=${lotId}`)
            ))

            ids.forEach(lotId => {
                const index = lots.findIndex(c => c.id === lotId)
                if (index !== -1) {
                    lots.splice(index, 1)
                }
            })

            return res
        },

        async sortLots(context, { saleId, specialId, highlightIds }) {
            return Vue.prototype.$api.patch(`/sales/${saleId}/specials/${specialId}/highlights`, {
                highlight_ids: highlightIds
            })
        }
    }
}
