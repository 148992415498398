<template>
    <v-menu>
        <template #activator="props">
            <slot name="activator" v-bind="props"></slot>
        </template>
        <v-list>
            <v-list-item @click="$emit('click:import', { images: true })">
                <v-list-item-action>
                    <v-icon small color="green">$vuetify.icons.importSale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salevacations.importImgYes') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$emit('click:import', { images: false })">
                <v-list-item-action>
                    <v-icon small color="red">$vuetify.icons.importSale</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('salevacations.importImgNo') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'hdv-sale-highlight-item-import'
}
</script>
