<template>
    <v-card>
        <v-form v-model="valid" @submit.stop.prevent="submit" v-if="sale" :disabled="loading" ref="form">
            <v-card-text>
                <hdv-sale-nx-import
                    :title="sale.title"
                    :id="!nx ? saleId : null"
                    :nx-id="sale.nx_id || saleId" />

                <template v-if="sale.imported">

                    <hdv-sale-status v-model="sale.status" :slug="sale.slug_i18n && sale.slug_i18n.fr" />

                    <vlab-base-switch v-model="sale.is_house" :label="$t('sale.ishouse')" />

                    <hdv-base-i18n-area :loading="loading">
                        <template #default="{ ln, isDefault }">
                            <hdv-sale-title v-model="sale.title_i18n[ln]" :required="isDefault" :name="`title_i18n-${ln}`" />
                            <hdv-base-slug-field v-model="sale.slug_i18n[ln]" :required="isDefault" :auto-fill="sale.title_i18n[ln]" :name="`slug_i18n-${ln}`" />
                            <hdv-sale-important v-model="sale.important_i18n[ln]" />
                            <hdv-sale-description v-model="sale.description_i18n[ln]" :id="saleId" />
                            <hdv-sale-place v-model="sale.place_i18n[ln]" :id="saleId" />
                        </template>
                    </hdv-base-i18n-area>

                    <hdv-sale-highlights-lots v-model="sale.highlights_lots" />

                    <hdv-sale-catalog-date v-model="sale.catalog_date" />

                    <hdv-base-image
                        :label="$t('sale.image')"
                        :preview.sync="sale.image_url_preview"
                        :src.sync="sale.image_url"
                        :url="`/sales/${this.saleId}/image`"
                        @remove="removeImage('image')"
                        @loaded="v => loading = v"
                        auto-start />

                    <hdv-base-image
                        :label="$t('salecatalog.uploadBanner')"
                        :preview.sync="sale.banner_url_preview"
                        :src.sync="sale.banner_url"
                        :url="`/sales/${this.saleId}/banner`"
                        @remove="removeImage('banner')"
                        @loaded="v => loading = v"
                        auto-start />

                    <hdv-base-image
                        :label="$t('salecatalog.uploadcover')"
                        :preview.sync="sale.cover_url_preview"
                        :src.sync="sale.cover_url"
                        :url="`/sales/${this.saleId}/cover`"
                        @remove="removeImage('cover')"
                        @loaded="v => loading = v"
                        auto-start />

                    <hdv-sale-special-upload
                        :sale-id="saleId"
                        v-model="sale.catalog_url"
                        pdf
                        inline
                        model="sale"
                        type="catalog"
                        @loaded="v => loading = v"
                        class="mb-3">
                        <template #append>
                            <vlab-base-message-btn
                                v-if="sale.catalog_url"
                                :message="$t('salespecial.removeCatalog')"
                                :message-title="$t('salespecial.removeCatalogTitle')"
                                :message-btn="$t('base.remove')"
                                :loading="loading"
                                @submit="removeDoc('catalog')"
                                color="red"
                                icon />
                            <v-btn color="red" icon target="_blank" :href="sale.catalog_url" :disabled="!sale.catalog_url">
                                <v-icon>$vuetify.base.saleCatalogPdf</v-icon>
                            </v-btn>
                        </template>
                    </hdv-sale-special-upload>

                    <hdv-sale-special-upload
                        :sale-id="saleId"
                        v-model="sale.results_url"
                        pdf
                        inline
                        model="sale"
                        type="results"
                        @loaded="v => loading = v">
                        <template #append>
                            <vlab-base-message-btn
                                v-if="sale.results_url"
                                :message="$t('salespecial.removeResults')"
                                :message-title="$t('salespecial.removeResultsTitle')"
                                :message-btn="$t('base.remove')"
                                :loading="loading"
                                @submit="removeDoc('results')"
                                color="red"
                                icon />
                            <v-btn color="red" icon target="_blank" :href="sale.results_url" :disabled="!sale.results_url">
                                <v-icon>$vuetify.base.saleCatalogPdf</v-icon>
                            </v-btn>
                        </template>
                    </hdv-sale-special-upload>

                    <hdv-sale-special-issuu v-model="sale.issuu_url" class="mt-5" />

                </template>
            </v-card-text>
            <v-card-actions v-if="sale.imported">
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    :loading="loading">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseMessageBtn from '@/shared/components/base/VlabBaseMessageBtn'
import VlabBaseSwitch from '@/shared/components/base/VlabBaseSwitch.vue'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvBaseImage from '@/components/base/HdvBaseImage'
import HdvBaseSlugField from '@/components/base/HdvBaseSlugField'
import HdvSaleStatus from '@/components/sale/HdvSaleStatus'
import HdvSaleTitle from '@/components/sale/HdvSaleTitle'
import HdvSaleImportant from '@/components/sale/HdvSaleImportant'
import HdvSaleDescription from '@/components/sale/HdvSaleDescription'
import HdvSalePlace from '@/components/sale/HdvSalePlace'
import HdvSaleCatalogDate from '@/components/sale/HdvSaleCatalogDate'
import HdvSaleNxImport from '@/components/sale/HdvSaleNxImport'
import HdvSaleSpecialUpload from '@/components/sale-special/HdvSaleSpecialUpload'
import HdvSaleSpecialIssuu from '@/components/sale-special/HdvSaleSpecialIssuu'
import HdvSaleHighlightsLots from '@/components/sale/HdvSaleHighlightsLots'

export default {
    name: 'sale-form',
    components: {
        VlabBaseMessageBtn,
        VlabBaseSwitch,
        HdvBaseImage,
        HdvBaseI18nArea,
        HdvBaseSlugField,
        HdvSaleImportant,
        HdvSaleStatus,
        HdvSaleTitle,
        HdvSaleDescription,
        HdvSalePlace,
        HdvSaleCatalogDate,
        HdvSaleNxImport,
        HdvSaleHighlightsLots,
        HdvSaleSpecialUpload,
        HdvSaleSpecialIssuu
    },
    props: {
        saleId: { type: [String, Number] },
        nx: { type: Boolean }
    },
    data: () => ({
        loading: false,
        valid: false,
        sale: null
    }),
    watch: {
        saleId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('sale/get', { id: this.saleId, edit: true })
                .then(sale => {
                    this.sale = sale
                    this.$emit('loaded', sale)
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        submit() {
            this.loading = true
            return this.$store.dispatch('sale/update', { id: this.sale.id, sale: this.sale })
                .then(sale => {
                    this.$success(sale, { message: 'sale.saved' })
                })
                .catch(err => this.$err(err, null, this.$refs.form))
                .finally(() => (this.loading = false))
        },

        removeDoc(type) {
            this.loading = true
            return this.$store.dispatch('sale/removeDoc', { saleId: this.saleId, type })
                .then(res => {
                    this.sale[`${type}_url`] = null
                    const message = type === 'catalog' ? 'salespecial.removeCatalogDone' : 'salespecial.removeResultsDone'
                    this.$success(res, { message })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeImage(type) {
            this.loading = true
            return this.$store.dispatch('sale/removeImage', { saleId: this.saleId, type })
                .then(res => {
                    this.sale[`${type}_url`] = null
                    this.sale[`${type}_url_preview`] = null
                    this.$success(res, { message: this.$i18n.t('base.imageRemoved', { type }) })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
