<template>
    <v-card flat>
        <vlab-base-card-title :title="$t('salespecial.list')" :dismissible="false" hide-spacer>
            <template #buttons>
                <v-spacer />
                <v-btn rounded outlined dark small :to="{ name: 'salespecialform', params: { specialId: 'add' } }">
                    {{ $t('base.add') }}
                </v-btn>
            </template>
        </vlab-base-card-title>
        <div class="my-5 px-5" v-marked="$t('salespecial.help')"></div>
        <v-list>
            <hdv-base-actionable-list
                v-model="action"
                :total="specials.length"
                :selected.sync="selected"
                @close="selected = []"
                @remove="removeItems"
                :force-visible="true"
                :disabled="loading">
                <template v-if="selected" #remove>
                    <v-card-text>{{ $t('salespecial.removeText', { count: selected.length, name: selected.length && selected[0].name }) }}</v-card-text>
                </template>
                <template #search>
                    <v-btn rounded outlined small @click="toggleSort" :disabled="loading">
                        {{ $t(sorting ? 'base.sorting' : 'base.sort') }}
                    </v-btn>
                </template>
            </hdv-base-actionable-list>
            <v-list-item-group v-model="selected" :multiple="action">

                <draggable
                    :list="sales"
                    :disabled="!sorting || loading"
                    class="list-group"
                    v-bind="dragOptions"
                    @start="$emit('drag', true)"
                    @end="$emit('drag', false)">
                    <div v-for="special in specials" :key="special.id">
                        <hdv-sale-special-list-item
                            :action="action"
                            :special="special"
                            :sorting="sorting"
                            @click="!sorting && $emit('select', special)" />
                        <v-divider/>
                    </div>
                </draggable>
            </v-list-item-group>
            <hdv-base-empty-list :value="!loading && !specials.length" :text="$t('salespecial.empty')" />
            <vlab-base-loader v-model="loading" />
        </v-list>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import HdvSaleSpecialListItem from '@/components/sale-special-list/HdvSaleSpecialListItem'
import HdvBaseEmptyList from '@/components/base/HdvBaseEmptyList'
import HdvBaseActionableList from '@/components/base/HdvBaseActionableList'
import draggable from 'vuedraggable'

import lodash from 'lodash'

export default {
    name: 'hdv-special-list',
    components: { VlabBaseCardTitle, HdvSaleSpecialListItem, HdvBaseEmptyList, HdvBaseActionableList, draggable },
    props: {
        saleId: { type: String },
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false,
        search: null,
        filter: null,
        sorting: false,
        dragOptions: {
            animation: 0,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost'
        },
        // actionable list
        action: false,
        selected: []
    }),
    computed: {
        sales() {
            return this.$store.getters['spec/specials']
        },

        specials() {
            const tokens = lodash.deburr(lodash.trim(this.filter)).toLowerCase().split(' ').filter(t => t.length >= 3)
            return this.$store.getters['spec/specials'].filter(special => {
                if (!tokens.length) {
                    return true
                }
                const query = lodash.deburr(`${special.title}`).toLowerCase()
                for (let i = 0; i < tokens.length; i += 1) {
                    if (query.indexOf(tokens[i]) === -1) {
                        return false
                    }
                }
                return true
            })
        },
        language() {
            return this.$store.getters['ln/current']
        }
    },
    watch: {
        search: 'doSearch',
        saleId: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        load() {
            if (this.disabled) {
                return
            }
            this.loading = true
            return this.$store.dispatch('spec/list', { sale_id: this.saleId })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeItems() {
            this.loading = true
            return this.$store.dispatch('spec/removeByIds', { sale_id: this.saleId, ids: this.selected })
                .then(res => {
                    this.selected = []
                    this.$success(res, { message: 'salespecial.removed' })
                    this.action = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSearch: lodash.debounce(function() {
            this.filter = this.search
        }, 500),

        toggleSort() {
            if (!this.sorting) {
                this.sorting = true
                return
            }
            // on enregistre l'état du sort
            this.sorting = false
            this.loading = true
            return this.$store.dispatch('spec/sorts', { sale_id: this.saleId, special_ids: this.sales.map(s => s.id) })
                .then(res => this.$success(res, { message: 'base.sorted' }))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
