<template>
    <v-toolbar
        dense
        :flat="!action" :color="action ? 'primary' : ''"
        :dark="action"
        v-show="forceVisible || total > 0">
        <div v-show="!action && !unactionable">
            <v-checkbox
                v-model="action"
                color="primary"
                single-line
                hide-details
                :label="$t('actionablelist.select')"
                :disabled="disabled" />
        </div>
        <template v-if="action">
            <v-checkbox v-model="selectAll" single-line hide-details :label="$t('actionablelist.selectcount', { count: selected && selected.length })" />
            <v-spacer />
            <v-icon small :disabled="disabled || !selected || !selected.length" class="mr-5" color="red" @click="remove = true">$vuetify.icons.baseDelete</v-icon>
            <slot name="buttons"></slot>
            <v-icon small :disabled="disabled" @click="close">$vuetify.icons.baseClose</v-icon>
        </template>

        <template v-else>
            <slot name="header"></slot>
            <v-spacer />
            <slot name="search"></slot>
        </template>

        <v-dialog v-model="remove" max-width="400">
            <v-card>
                <vlab-base-card-title :title="$t('base.remove')" color="red" @close="remove = false" />
                <slot name="remove"></slot>
                <v-divider />
                <v-card-actions>
                    <v-btn block rounded dark color="red" @click="doRemove">{{ $t('base.remove') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-toolbar>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'

export default {
    name: 'hdv-base-actionable-list',
    components: { VlabBaseCardTitle },
    props: {
        unactionable: { type: Boolean },
        selected: { type: [Array, Number, String] },
        forceVisible: { type: Boolean },
        total: { type: Number },
        disabled: { type: Boolean },
        value: { type: Boolean }
    },
    data: () => ({
        remove: false,
        selectAll: false
    }),
    computed: {
        action: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        action(v) {
            if (!v) {
                this.$emit('close')
            } else if (v && (this.selected || this.selected === 0) && !Array.isArray(this.selected)) {
                this.$emit('update:selected', [this.selected])
            }
        },
        selectAll(v) {
            if (!v) {
                this.$emit('update:selected', [])
            } else {
                this.$emit('update:selected', Array.from({ length: this.total }, (v, k) => k))
            }
        }
    },
    methods: {
        doRemove() {
            this.remove = false
            this.$emit('remove')
        },

        close() {
            this.action = false
            this.selectAll = false
        }
    }
}
</script>
