import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        contents: [],
        blockTypes: [
            { id: 'text' },
            { id: 'image' },
            { id: 'pagelink' },
            { id: 'internalslug' }
        ],
        groups: [
            {
                value: 'banner-current-sale',
                text: 'Banner des ventes en cours',
                blockConfig: [
                    { type: 'image', alias: 'image' },
                    { type: 'text', alias: 'subtitle' },
                    { type: 'text', alias: 'button' },
                    { type: 'internalslug', alias: 'link' }
                ]
            },
            {
                value: 'banner-previous-sale',
                text: 'Banner des ventes passées',
                blockConfig: [
                    { type: 'image', alias: 'image' },
                    { type: 'text', alias: 'subtitle' },
                    { type: 'text', alias: 'button' },
                    { type: 'internalslug', alias: 'link' }
                ]
            },
            {
                value: 'banner-next-sale',
                text: 'Banner des ventes futures',
                blockConfig: [
                    { type: 'image', alias: 'image' },
                    { type: 'text', alias: 'subtitle' },
                    { type: 'text', alias: 'button' },
                    { type: 'internalslug', alias: 'link' }
                ]
            }
        ]
    },

    getters: {
        contents: state => state.contents,
        groups: state => state.groups,
        blockTypes: state => state.blockTypes
    },

    mutations: {
    },

    actions: {
        async list({ state }, { search, offset, limit }) {
            if (!search && limit > 100 && state.contents.length) {
                return state.contents
            }
            const res = await Vue.prototype.$api.get('/contents/components', {
                params: {
                    include_count: true,
                    mapping: 'list',
                    search,
                    offset,
                    limit
                }
            })
            if (!search && limit > 100) {
                state.contents = res.data
            }
            return { contents: res.data, total: Number(res.headers['x-hdv-list-count']) }
        },

        async get(context, { contentId }) {
            const res = await Vue.prototype.$api.get(`/contents/components/${contentId}`, {
                meta: { lang: '*' },
                params: {
                    blocks_as_array: true
                }
            })
            return res.data
        },

        async add({ state }, { content }) {
            const res = await Vue.prototype.$api.post('/contents/components', content)

            state.contents.push({ ...res.data })
            return res.data
        },

        async update({ state }, { contentId, content }) {
            await Vue.prototype.$api.put(`/contents/components/${contentId}`, content)

            const index = state.contents.findIndex(c => c.id === contentId)
            if (index !== -1) {
                state.contents.splice(index, 1, { ...content })
            }
        },

        async remove({ state }, { contentId, contents = null }) {
            await Vue.prototype.$api.delete(`/contents/components/${contentId}`)

            const index = (contents || state.contents).findIndex(c => c.id === contentId)
            if (index !== -1) {
                (contents || state.contents).splice(index, 1)
            }
        },

        async removeByIndexes({ state }, { indexes, contents = null }) {
            let data = (contents || state.contents)

            const ids = data.filter((_, i) => indexes.indexOf(i) !== -1).map(item => item.id)
            const res = await Promise.all(ids.map(contentId =>
                Vue.prototype.$api.delete(`/contents/components/${contentId}`)
            ))

            data = data.filter((_, i) => !indexes.includes(i))

            return res
        },

        async addBlock(context, { contentId, block }) {
            const res = await Vue.prototype.$api.post(`/contents/components/${contentId}/blocks`, block)
            return res.data
        },

        async updateBlock(context, { contentId, blockId, block }) {
            await Vue.prototype.$api.put(`/contents/components/${contentId}/blocks/${blockId}`, block)
        },

        async removeBlock(context, { contentId, blockId }) {
            await Vue.prototype.$api.delete(`/contents/components/${contentId}/blocks/${blockId}`)
        }
    }
}
