<template>
    <v-card :loading="loading">
        <vlab-base-card-title :title="$t('event.form')" @close="back" />
        <v-tabs v-model="tab" centered text background-color="grey lighten-5">
            <v-tab :href="`#form`">
                {{ $t('event.formtab') }}
            </v-tab>
            <v-tab :href="`#planning`">
                {{ $t('event.planning') }}
            </v-tab>
        </v-tabs>
        <v-form ref="form" v-model="valid" @submit.stop.prevent="submit" :disabled="loading">
            <v-tabs-items v-model="tab">
                <v-tab-item value="form">
                    <v-card-text>
                        <hdv-event-type v-model="event.type" />

                        <vlab-base-switch v-model="event.disabled" inverse :label="$t('event.disable')" />

                        <template v-if="['exposition', 'sale'].includes(event.type)">
                            <hdv-sale-list-selector
                                v-model="event.sale_id"
                                dense
                                clearable
                                only-imported
                            />
                            <hdv-sale-vacation-list-selector
                                v-if="event.sale_id"
                                v-model="event.vacation_id"
                                :sale-id="event.sale_id"
                            />
                        </template>

                        <hdv-base-i18n-area :loading="loading">
                            <template #default="{ ln, isDefault }">
                                <hdv-event-header
                                    v-model="event.header_i18n[ln]"
                                    :required="isDefault"
                                    :name="`header_i18n-${ln}`" />

                                <hdv-event-name
                                    v-model="event.name_i18n[ln]"
                                    :required="isDefault"
                                    :name="`name_i18n-${ln}`" />

                                <hdv-base-markdown-editor
                                    v-model="event.description_i18n[ln]"
                                    :id="edit ? event.id : id"
                                    :label="$t('event.description')" />

                                <hdv-event-address
                                    v-model="event.city_i18n[ln]"
                                    :street.sync="event.street_i18n[ln]"
                                    :info.sync="event.info_i18n[ln]"
                                    :zip.sync="event.zip"
                                    :id="edit ? event.id : id"
                                    :required="isDefault" />

                            </template>
                        </hdv-base-i18n-area>

                        <hdv-base-image
                            ref="upload"
                            :auto-start="!!event.id"
                            :preview.sync="event.image_url_preview"
                            :src.sync="event.image_url"
                            :label="$t(`event.image`)"
                            :url="`/events/${event.id}/image`"
                            @loaded="v => loading = v"
                            class="mb-5" />

                        <v-subheader>{{ $t('event.contact') }}</v-subheader>
                        <vlab-user-email-field v-model="event.email" optional />
                        <vlab-base-phone-field v-model="event.phone" :label="$t('user.phone')" country="ch" />

                        <hdv-team-selector v-model="event.team_id" />
                        <hdv-department-selector v-if="!edit || event.id" v-model="event.department_ids" />
                    </v-card-text>
                </v-tab-item>
                <v-tab-item value="planning">
                    <v-card-text>
                        <hdv-event-active v-model="event.active" />
                        <hdv-event-key v-if="event.active" v-model="event.key" :required="event.active" />

                        <hdv-event-date-slot
                            v-for="(d, i) in event.dates"
                            :key="i"
                            :id="i"
                            :date.sync="d.date"
                            :slots.sync="d.slots"
                            :date-start.sync="d.date_start"
                            :date-end.sync="d.date_end"
                            :timezone="event.timezone"
                            :min-date="i > 0 ? event.dates[i - 1].date : null"
                            :max-date="i < event.dates.length - 1 ? event.dates[i + 1].date : null"
                            @remove="removeDateSlot(i)" />

                        <v-btn outlined rounded @click="addDateSlot">{{ $t('event.dateslotadd') }}</v-btn>
                        <v-input type="hidden" :rules="[$required]" :value="event.dates.length ? true : null" />
                    </v-card-text>
                </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    rounded
                    color="primary"
                    type="submit"
                    :loading="loading"
                    :disabled="!valid">
                    {{ $t('base.save') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VlabBaseCardTitle from '@/shared/components/base/VlabBaseCardTitle'
import VlabBasePhoneField from '@/shared/components/base/VlabBasePhoneField'
import VlabUserEmailField from '@/shared/components/user/VlabUserEmailField'
import HdvBaseI18nArea from '@/components/base/HdvBaseI18nArea'
import HdvBaseMarkdownEditor from '@/components/base/HdvBaseMarkdownEditor'
import HdvEventDateSlot from '@/components/event/HdvEventDateSlot'
import HdvEventHeader from '@/components/event/HdvEventHeader'
import HdvEventName from '@/components/event/HdvEventName'
import HdvEventKey from '@/components/event/HdvEventKey'
import HdvEventAddress from '@/components/event/HdvEventAddress'
import HdvEventActive from '@/components/event/HdvEventActive'
import HdvEventType from '@/components/event/HdvEventType'
import HdvSaleListSelector from '@/components/sale-list/HdvSaleListSelector'
import HdvSaleVacationListSelector from '@/components/sale-vacation-list/HdvSaleVacationListSelector'
import HdvTeamSelector from '@/components/team-list/HdvTeamSelector'
import HdvBaseImage from '@/components/base/HdvBaseImage'
import HdvDepartmentSelector from '@/components/department-list/HdvDepartmentSelector'
import VlabBaseSwitch from '@/shared/components/base/VlabBaseSwitch'

import lodash from 'lodash'

const value = {
    id: null,
    name_i18n: {},
    description_i18n: {},
    header_i18n: {},
    street_i18n: {},
    city_i18n: {},
    info_i18n: {},
    key: null,
    active: false,
    timezone: 'Europe/Zurich',
    dates: []
}

export default {
    name: 'event-form',
    props: {
        id: { type: [String, Number], default: null }
    },
    components: {
        VlabBaseCardTitle,
        VlabUserEmailField,
        VlabBasePhoneField,
        HdvBaseI18nArea,
        HdvBaseMarkdownEditor,
        HdvEventDateSlot,
        HdvEventName,
        HdvEventHeader,
        HdvEventKey,
        HdvEventAddress,
        HdvBaseImage,
        HdvEventActive,
        HdvEventType,
        HdvSaleListSelector,
        HdvSaleVacationListSelector,
        HdvTeamSelector,
        HdvDepartmentSelector,
        VlabBaseSwitch
    },
    data: () => ({
        loading: false,
        valid: false,
        tab: null,
        departments: [],
        event: lodash.cloneDeep(value)
    }),
    computed: {
        edit() {
            return this.id !== 'add'
        }
    },
    watch: {
        $route: 'load',
        'event.type'(type, old) {
            if (!type || (!old && this.event.header_i18n.fr !== this.$i18n.t(`event.types.${type}`))) {
                return
            }
            this.event.header_i18n.fr = this.$i18n.t(`event.types.${type}`)
        }
    },
    mounted() {
        return this.load()
    },
    methods: {

        load() {
            if (!this.edit) {
                this.event = lodash.cloneDeep(value)
                return
            }
            this.loading = true
            return this.$store.dispatch('res/get', { id: this.id })
                .then(event => (this.event = event))
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        back() {
            this.$router.push({ name: 'eventlist' })
        },

        addDateSlot() {
            this.event.dates.push({
                date: null,
                date_start: null,
                date_end: null,
                timezone: this.event.timezone,
                slots: []
            })
        },

        removeDateSlot(index) {
            this.event.dates.splice(index, 1)
        },

        submit() {
            this.loading = true

            this.event.date_start = null
            this.event.date_end = null
            if (this.event.dates.length) {
                this.event.date_start = this.event.dates[0].date_start || this.event.dates[0].date
                this.event.date_end = this.event.dates[this.event.dates.length - 1].date_end || this.event.dates[this.event.dates.length - 1].date
            }

            const action = this.edit ? 'res/update' : 'res/create'
            return this.$store.dispatch(action, { id: this.event.id, event: this.event })
                .then(async event => {
                    if (!this.edit) {
                        this.event.id = event.id
                        await this.$refs.upload.upload()
                    }

                    await this.$store.dispatch('dp/updateDepartments', { id: this.event.id, departmentIds: this.event.department_ids })

                    this.$success(event, { message: 'event.saved' })
                    if (!this.edit) {
                        this.$router.push({ name: 'eventform', params: { id: event.id } })
                    }
                })
                .catch(err => this.$err(err, null, this.$refs.form))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
