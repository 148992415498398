<template>
    <v-autocomplete
        v-model="selected"
        :label="$t('contentpage.selector')"
        :items="items"
        :search-input.sync="search"
        :loading="loading"
        :dense="dense"
        item-text="name"
        item-value="id"
        outlined>
        <template #item="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.alias }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'hdv-content-page-selector',
    props: {
        value: { type: String },
        dense: { type: Boolean }
    },
    data: () => ({
        search: null,
        loading: false
    }),
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        items() {
            return this.$store.getters['page/pagesFlat']
        }
    },

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('page/pagesFlat')
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
