import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        faq: []
    },

    getters: {
        faq: state => state.faq
    },

    mutations: {
    },

    actions: {
        async list({ state }, { search }) {
            if (state.faq.length) {
                return state.faq
            }
            const res = await Vue.prototype.$api.get('/contents/faq', {
                params: {
                    mapping: 'list',
                    include_disabled: true
                }
            })
            state.faq = res.data
            return state.faq
        },

        async get(context, { faqId }) {
            const res = await Vue.prototype.$api.get(`/contents/faq/${faqId}`, {
                meta: { lang: '*' }
            })
            return res.data
        },

        async add({ state }, { faq }) {
            const res = await Vue.prototype.$api.post('/contents/faq', faq)

            state.faq.push({ ...res.data })
            return res.data
        },

        async update({ state }, { faqId, faq }) {
            await Vue.prototype.$api.put(`/contents/faq/${faqId}`, faq)

            const index = state.faq.findIndex(c => c.id === faqId)
            if (index !== -1) {
                state.faq.splice(index, 1, { ...faq })
            }
        },

        async remove({ state }, { faqId }) {
            await Vue.prototype.$api.delete(`/contents/faq/${faqId}`)

            const index = state.faq.findIndex(c => c.id === faqId)
            if (index !== -1) {
                state.faq.splice(index, 1)
            }
        },

        async removeByIndexes({ state }, { indexes }) {
            const ids = state.faq.filter((_, i) => indexes.indexOf(i) !== -1).map(item => item.id)
            const res = await Promise.all(ids.map(faqId =>
                Vue.prototype.$api.delete(`/contents/faq/${faqId}`)
            ))

            state.faq = state.faq.filter((_, i) => !indexes.includes(i))

            return res
        },

        sorts(context, { faqIds }) {
            return Vue.prototype.$api.post('/contents/faq-sort', { content_faq_ids: faqIds })
        }
    }
}
