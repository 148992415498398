import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        categories: []
    },

    getters: {
        categories: state => state.categories
    },

    mutations: {
    },

    actions: {
        async list({ state }, { search }) {
            if (state.categories.length) {
                return state.categories
            }
            const res = await Vue.prototype.$api.get('/contents/categories')
            state.categories = res.data
            return state.categories
        },

        async get(context, { categoryId }) {
            const res = await Vue.prototype.$api.get(`/contents/categories/${categoryId}`, {
                meta: { lang: '*' }
            })
            return res.data
        },

        async add({ state }, { category }) {
            const res = await Vue.prototype.$api.post('/contents/categories', category)

            state.categories.push({ ...res.data })
            return res.data
        },

        async update({ state }, { categoryId, category }) {
            await Vue.prototype.$api.put(`/contents/categories/${categoryId}`, category)

            const index = state.categories.findIndex(c => c.id === categoryId)
            if (index !== -1) {
                state.categories.splice(index, 1, { ...category })
            }
        },

        async remove({ state }, { categoryId }) {
            await Vue.prototype.$api.delete(`/contents/categories/${categoryId}`)

            const index = state.categories.findIndex(c => c.id === categoryId)
            if (index !== -1) {
                state.categories.splice(index, 1)
            }
        },

        async removeByIndexes({ state }, { indexes }) {
            const ids = state.categories.filter((_, i) => indexes.indexOf(i) !== -1).map(item => item.id)
            const res = await Promise.all(ids.map(categoryId =>
                Vue.prototype.$api.delete(`/contents/categories/${categoryId}`)
            ))

            state.categories = state.categories.filter((_, i) => !indexes.includes(i))

            return res
        }
    }
}
