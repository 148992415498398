import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        departments: [],
        count: 0
    },

    getters: {
        departments: state => state.departments
    },

    mutations: {
    },

    actions: {
        async list({ state }, { search, offset, limit, ids }) {
            const [resDep, resCount] = await Promise.all([
                Vue.prototype.$api.get('/departments', {
                    params: {
                        mapping: 'list',
                        ids,
                        limit,
                        offset,
                        search
                    }
                }),
                offset ? null : Vue.prototype.$api.head('/departments', {
                    params: {
                        search,
                        ids
                    }
                })
            ])

            state.departments = resDep.data

            if (resCount) {
                state.count = Number(resCount.headers['x-hdv-list-count'] || 0)
            }

            return { items: state.departments, total: state.count }
        },

        async get(context, { departmentId }) {
            const res = await Vue.prototype.$api.get(`/departments/${departmentId}`, {
                meta: { lang: '*' },
                params: {
                    include_highlights: true,
                    include_teams: true
                }
            })
            return res.data
        },

        async add({ state }, { department }) {
            const res = await Vue.prototype.$api.post('/departments', department)

            if (department.team_ids?.length) {
                await Vue.prototype.$api.post(`/departments/${res.id}/teams`, { team_ids: department.team_ids })
            }

            state.departments.push({ ...res.data })
            return res.data
        },

        async update({ state }, { departmentId, department }) {
            department.teams = undefined
            await Vue.prototype.$api.put(`/departments/${departmentId}`, department)

            if (department.team_ids?.length) {
                await Vue.prototype.$api.post(`/departments/${departmentId}/teams`, { team_ids: department.team_ids })
            }

            const index = state.departments.findIndex(c => c.id === departmentId)
            if (index !== -1) {
                state.departments.splice(index, 1, { ...department })
            }
        },

        async updateDepartments(context, { id, departmentIds }) {
            await Vue.prototype.$api.post(`/events/${id}/departments`, {
                ids: departmentIds
            })
        },

        async remove({ state }, { departmentId }) {
            await Vue.prototype.$api.delete(`/departments/${departmentId}`)

            const index = state.departments.findIndex(c => c.id === departmentId)
            if (index !== -1) {
                state.departments.splice(index, 1)
            }
        },

        async removeByIndexes({ state, dispatch }, { indexes }) {
            const ids = state.departments.filter((_, i) => indexes.indexOf(i) !== -1).map(item => item.id)
            const res = await Promise.all(ids.map(departmentId =>
                Vue.prototype.$api.delete(`/departments/${departmentId}`)
            ))

            state.departments = state.departments.filter((_, i) => !indexes.includes(i))

            return res
        },

        async listTags(context, { filter }) {
            const res = await Vue.prototype.$api.get('/nx/items/tags', {
                params: {
                    filter
                }
            })
            return res.data
        },

        async listTeams(context, { id }) {
            const res = await Vue.prototype.$api.get(`/departments/${id}/teams`)
            return res.data
        },

        async highlights(context, { filter, departmentId, limit }) {
            const res = await Vue.prototype.$api.get(`/departments/${departmentId}/highlights`, {
                params: {
                    filter,
                    limit
                }
            })
            return res.data
        },

        async addHighlight(context, { departmentId, lotId }) {
            await Vue.prototype.$api.put(`/departments/${departmentId}/highlights/${lotId}`)
        },

        async removeHighlight(context, { departmentId, lotId, lots }) {
            await Vue.prototype.$api.delete(`/departments/${departmentId}/highlights/${lotId}`)

            const index = lots.findIndex(c => c.id === lotId)
            if (index !== -1) {
                lots.splice(index, 1)
            }
        },

        async removeHighlightByIds(context, { departmentId, ids, lots }) {
            const res = await Promise.all(ids.map(lotId =>
                Vue.prototype.$api.delete(`/departments/${departmentId}/highlights/${lotId}`)
            ))

            ids.forEach(lotId => {
                const index = lots.findIndex(c => c.id === lotId)
                if (index !== -1) {
                    lots.splice(index, 1)
                }
            })

            return res
        }
    }
}
