import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        nxSaleId: {}
    },

    actions: {

        async listWithNx({ state, dispatch }, { saleId, offset, limit, search, updated_at }) {
            if (!state.nxSaleId[saleId]) {
                const res = await Vue.prototype.$api.get(`/sales/${saleId}`)
                state.nxSaleId[saleId] = res.data.nx_id
            }
            const nxSaleId = state.nxSaleId[saleId]

            const [nxLots, nxCount] = await Promise.all([
                dispatch('listFromNx', { nxSaleId, range: search, updated_at, offset, limit }),
                offset ? null : Vue.prototype.$api.head(`/nx/sales/${nxSaleId}/lots`, {
                    params: {
                        range: search,
                        updated_at,
                        vacation_ids: []
                    }
                })
            ])

            const count = nxCount ? Number(nxCount.headers['x-hdv-list-count']) : null

            const res = !nxLots.length ? { data: [] } : await Vue.prototype.$api.get(`/sales/${saleId}/lots`, {
                params: {
                    mode: 'db',
                    nx_lot_ids: nxLots.map(nxLot => nxLot.id)
                }
            })

            const indexed = res.data.reduce((acc, lot) => {
                acc[lot.nx_id] = lot
                return acc
            }, {})

            const lots = nxLots.map(nxLot => {
                const lot = indexed[nxLot.id] || { item: {} }
                return {
                    web: !!lot.id,
                    id: lot.id || nxLot.id,
                    category_id: lot.category_id || nxLot.category.id,
                    vacation_id: lot.vacation_id || nxLot.vacation.id,
                    lot_nb: lot.lot_nb || nxLot.lotId,
                    lot_nb_ext: lot.lot_nb_ext || nxLot.lotIdExt,
                    item: {
                        estimate_down: lot.item.estimate_down || nxLot.estimates.down,
                        estimate_up: lot.item.estimate_up || nxLot.estimates.up,
                        images: lot.item.images || (nxLot._links?.images || []),
                        title: lot.item.title || nxLot.title
                    }
                }
            })

            return { lots, count }
        },

        async listFromNx(context, { nxSaleId, range, updated_at, nxVacationIds, offset, limit }) {
            const res = await Vue.prototype.$api.get(`/nx/sales/${nxSaleId}/lots`, {
                params: {
                    offset,
                    limit,
                    range,
                    updated_at,
                    vacation_ids: Array.isArray(nxVacationIds) ? nxVacationIds : (nxVacationIds ? [nxVacationIds] : [])
                }
            })
            return res.data
        },

        async list(context, { saleId, range, updated_at, vacationIds, limit, offset }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/lots`, {
                params: {
                    offset,
                    limit,
                    lots_range: range,
                    updated_at,
                    vacation_ids: Array.isArray(vacationIds) ? vacationIds : (vacationIds ? [vacationIds] : []),
                    mode: 'db'
                }
            })
            return res.data
        },

        async importAm(context, { saleId, lot, withImages }) {
            const res = await Vue.prototype.$api.post(`/sales/${saleId}/am/lots/${lot.id}`, {
                with_images: withImages
            })
            return res.data
        },

        async importAmQueue(context, { saleId, range, vacationIds, withImages }) {
            const res = await Vue.prototype.$api.post(`/sales/${saleId}/am/lots`, {
                with_images: withImages,
                filters: {
                    lots_range: range,
                    vacation_ids: Array.isArray(vacationIds) ? vacationIds : (vacationIds ? [vacationIds] : [])
                }
            })
            return res.data
        },

        async import(context, { saleId, nxLot, withImages }) {
            const params = {
                title: nxLot.title,
                title_i18n: nxLot.title_i18n,
                description: nxLot.description,
                description_i18n: nxLot.description_i18n,
                estimate_down: nxLot.estimates.down,
                estimate_up: nxLot.estimates.up,
                reserve_price: nxLot.reserve,
                vat_on_hammer: nxLot.vatOnHammer,
                no_direct_sale: nxLot.noDirectSale,
                status: nxLot.status,
                stock_no: nxLot.stockNo,
                sell_price: Number(nxLot.saleResult),
                sell_price_ttc: nxLot.sell_price_ttc,
                sell_price_display_status: isNaN(nxLot.saleResult) ? nxLot.saleResult : null,
                lot_nb: nxLot.lotId,
                lot_nb_ext: nxLot.lotIdExt,
                nx_item_id: nxLot.itemId,
                nx_id: nxLot.id,
                tags: nxLot.tags,
                vacation_id: nxLot.vacationId,
                category_id: nxLot.categoryId,
                images: ((withImages && nxLot._links?.images) || []).map(image => ({
                    name: image.filename,
                    href: image.href
                }))
            }
            const res = await Vue.prototype.$api.post(`/sales/${saleId}/lots`, params)
            const lot = res.data

            if (nxLot.errata?.id) {
                await Vue.prototype.$api.post(`/sales/${saleId}/lots/${lot.id}/errata`, nxLot.errata)
            }
            if (nxLot.condition_report?.id) {
                await Vue.prototype.$api.post(`/items/${lot.item.id}/condition-reports`, nxLot.condition_report)
            }
            await Vue.prototype.$api.post(`/items/${lot.item.id}/stories`, {
                story_ids: (nxLot.stories || []).map(s => s.id)
            })
            if (nxLot.attributes?.length) {
                await Vue.prototype.$api.post(`/items/${lot.item.id}/attributes`, {
                    attributes: nxLot.attributes
                })
            }

            return lot
        },

        async get(context, { saleId, lotId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/lots/${lotId}`)
            return res.data
        },

        remove(context, { saleId, lotId }) {
            return Vue.prototype.$api.delete(`/sales/${saleId}/lots/${lotId}`)
        },

        async countByVacation(context, { saleId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/lots-vacations-count`)
            return res.data
        },

        async countByVacationNx(context, { nxSaleId }) {
            const res = await Vue.prototype.$api.get(`/nx/sales/${nxSaleId}/lots-vacations-count`)
            return res.data
        },

        async countByVacationAm(context, { saleId }) {
            const res = await Vue.prototype.$api.get(`/sales/${saleId}/am/lots-vacations-count`)
            return res.data
        }
    }
}
