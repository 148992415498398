import Vue from 'vue'

export default {
    namespaced: true,

    actions: {

        async addItem(context, { nxItemId, saleId, description, title }) {
            const res = await Vue.prototype.$api.post('/items', {
                nx_id: nxItemId,
                sale_id: saleId,
                description: description.fr,
                description_i18n: description,
                title: title.fr,
                title_i18n: title
            })
            return res.data
        },

        async updateItem(context, { itemId, saleId, images }) {
            const res = await Vue.prototype.$api.post(`/items/${itemId}`, {
                sale_id: saleId,
                images
            })
            return res.data
        },

        remove(context, { id }) {
            return Vue.prototype.$api.delete(`/items/${id}`)
        }
    }
}
